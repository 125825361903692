

import './login.css';
import React, { useEffect, useState } from 'react';
import { postApiRequest } from '../../../services/api';
import useToken from './../../../helper/useToken';
import CButton from 'components/atoms/CButton';

export default function Login() {

    const [postData, setPostData] = useState(null);
    const [ pageType, setPageType] = useState("logIn");
    const { token, setToken } = useToken();



    const updatePostData = (key , event) =>{
      var tmp = {...postData};
      tmp[key] = event.target.value;
      setPostData(tmp); 
    }


    const _callLogin = async () => {     

      console.log(postData);
      postApiRequest("Auth" , postData).then((res) => {
        
        setToken({token: res.data.accessToken});
        window.location.reload(); 
      }).catch((e) => {
        alert("error");
      });
      
    }


  const currentView = () => {
    switch(pageType) {
      case "signUp":
        return (
          <form>
            <h2>Sign Up!</h2>
            <fieldset>
              <legend>Create Account</legend>
              <ul>
                <li>
                  <label htmlFor="username">Username:</label>
                  <input type="text" id="username"  onChange={(event) => { updatePostData("email", event); }} required/>
                </li>
                <li>
                  <label htmlFor="email">Email:</label>
                  <input type="email" id="email" required/>
                </li>
                <li>
                  <label htmlFor="password">Password:</label>
                  <input type="password" id="password" required/>
                </li>
              </ul>
            </fieldset>
            <button>Submit</button>
            <button type="button" onClick={ () => setPageType("logIn")}>Have an Account?</button>
          </form>
        )
        break
      case "logIn":
        return (
          <form>
            <h2>Welcome SoundClub</h2>
            <fieldset>
              <legend>Log In</legend>
              <ul>
                <li>
                  <label htmlFor="username">Username:</label>
                  <input type="text" id="username" onChange={(event) => { updatePostData("email", event); }} required/>
                </li>
                <li>
                  <label htmlFor="password">Password:</label>
                  <input type="password" id="password" onChange={(event) => { updatePostData("password", event); }} required/>
                </li>
                <li>
                  <i/>
                  <a onClick={ () => setPageType("PWReset")} href="#">Forgot Password?</a>
                </li>
              </ul>
            </fieldset>
            <button type="button" onClick={ () => _callLogin()}>Login</button>
            <button type="button" onClick={ () => setPageType("signUp")}>Create an Account</button>
          </form>
        )
        break
      case "PWReset":
        return (
          <form>
          <h2>Reset Password</h2>
          <fieldset>
            <legend>Password Reset</legend>
            <ul>
              <li>
                <em>A reset link will be sent to your inbox!</em>
              </li>
              <li>
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" required/>
              </li>
            </ul>
          </fieldset>
          <button>Send Reset Link</button>
          <button type="button" onClick={ () => setPageType("logIn")}>Go Back</button>
        </form>
        )
      default:
        break
    }
  }


  return (
    <section id="entry-page">
      {currentView()}
    </section>
  )
}
