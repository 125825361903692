
import "./newuser.css"

export default function NewUser() {
  return (
    <div className="newUser">
      <h1 className="newUserTitle">New User</h1>
      <form action="" className="newUserForm">
          <div className="newUserItem">
              <label>Username</label>
              <input type='text' placeholder="joone" name="username" id="username"  />              
          </div>

          <div className="newUserItem">
              <label>Email</label>
              <input type='text' placeholder="joone@gmail.com" name="email" id='email' />              
          </div>

          <div className="newUserItem">
              <label>Password</label>
              <input type='password' placeholder="+1 344 543 34" />              
          </div>


          <div className="newUserItem">
              <label>Gender</label>
              <div className="newUserGender">
                  <input type="radio" className="" name="gender" id="mail" value='male' />
                  <label htmlFor="mail" className="">Male</label>
                  <input type="radio" className="" name="gender" id="female" value='female' />
                  <label htmlFor="female" className="">Femal</label>
              </div>
          </div>

      </form>
    </div>
  )
}
