

import "./editfestival.css";
import React, { useEffect, useState} from 'react';
import { getApiRequest, postApiRequest, postMultipartRequest } from "../../../services/api";
import { useLocation , useNavigate  } from 'react-router-dom';
import CButton from "components/atoms/CButton";
import CLabel from 'components/atoms/CLabel';
import LeftInputForms from "../partial/LeftInputForms";
import RightInputForms from "../partial/RightInputForms";



export default function EditFestival() {

    const [festival, setFestival] = useState(null);
    const [postData, setPostData] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    useEffect(() => {
        if(splitLocation.length > 0 ){
          console.log(splitLocation[splitLocation.length - 1]);
          _callFestival(splitLocation[splitLocation.length - 1]);
          setPostData({id:splitLocation[splitLocation.length - 1]});
        } 
    }, []);
              
    const updateFestival = (key , event) =>{
        var tmp = {...festival};
        if(event.target){
            tmp[key] = event.target.value;
        }else{
            tmp[key] = event;
        }        
        setFestival(tmp);

        var postTmp = {...postData};
        if(event.target){
            postTmp[key] = event.target.value;
        }else{
              postTmp[key] = event;
        }        
        setPostData(postTmp);
    }

    const _callFestival = (festivalId) => {        
        getApiRequest("festival/" + festivalId , {}).then((res) => {          
          setFestival(res.data);       
        }).catch((e) => {
        });
    }
  
    const _callUpdateFestival = () => {       
        
        const formData = new FormData();
        for(var key of Object.keys(postData)){      
            console.log("key", key);      
            formData.append(key, postData[key]);
        }          
        console.log("form post data", JSON.stringify(formData))     
        console.log(postData);
        console.log(postData['photos']);

        postMultipartRequest("festival" , formData ).then((res) => {            
          navigate('/festivals');
        }).catch((e) => {
          console.log("update" ,e)
        });
    }
    
    return (
        <div className='editFestival'>
            
            <CLabel label={'Edit Festival'} />

            <div className="rowContainer">
                
                <LeftInputForms festival={festival} updateFestival={(key, value) => {updateFestival(key, value); }}></LeftInputForms>

                <RightInputForms  festival={festival} updateFestival={(key, value) => {updateFestival(key, value); }}></RightInputForms>

            </div>

            <div className="updateBtnContainer">                            
                <CButton  onClick={() => {_callUpdateFestival()}}  > { festival ? 'Update' : 'Add'} </CButton>
            </div>           

        </div>
    )
}
