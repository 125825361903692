import "./festivallist.css";
import React , {  useEffect , useState  } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { deleteApiRequest, getApiRequest } from '../../../services/api';
import { DeleteOutline } from "@material-ui/icons";
import Images from "constants/Images";
import {  CImageButton, CModal, CButton ,CLabel } from "components/atoms";

export default function FestivalLists() {
    
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [modalOpened, setModalOpened] = useState(false);
    const [id, setId] = useState(0);

    const columns = [
        { field: 'id', headerName: 'ID', minWidth: 70  , maxWidth: 100},
        { field: 'title', headerName: 'Title', width: 300 , renderCell:(params) => {
            return (
                <div className="userListUser">                     
                    {params.row.title}
                </div>
            )
        } },
        { field: 'genre', headerName: 'genre', width: 100 },
        { field: 'country', headerName: 'country', width: 100 },
        { field: 'city', headerName: 'city', width: 200 },
        { field: 'isVirtual', headerName: 'isVirtual', width: 100 },
        { field: 'isInPerson', headerName: 'isInPerson', width: 100 },        
        {
            field: 'action',
            headerName: 'Action',
            width: 300,
            renderCell:(params) =>{
                return (
                    <>
                        <Link to={"/festival/" + params.row.id}>
                            <button className="userListEdit">Edit</button>
                        </Link>
                        <DeleteOutline className="userListDelete" onClick={() => {
                            console.log("delete ", params.row.id)
                            handleDelete(params.row.id)
                        }}/>
                    </>
                )
            }
        }
    ]; 
    

    useEffect(() => {      
       _callFestivalLists();
    }, [page]);

    const _callFestivalLists = () => {
        setIsLoading(true)
        getApiRequest("festivals", {isTag:0 , pageIndex: page + 1, pageSize: 10 } ).then((res) =>{            
            setRowCount(res.totalCount);
            setData(res.data);
            setIsLoading(false)
        }).catch((e) => {
            console.log("err" , e);
            setIsLoading(false)
        })
    }
    const handleDelete = (id) =>{
        setId(id);
        setModalOpened(true);
        //setData(data.filter(item => item.id != id));        
    }
    const onClose = ()=>{
        setModalOpened(false);
    }
    const onDelete = () =>{
        deleteApiRequest("festival/" + id , {}).then((res) => {            
            setData(data.filter(item => item.id != id));        
            setModalOpened(false);
        }).catch((e) => {
            console.log(e);
        })
    }
    
    return (
        <div className="festivalLists">

            <div className="headerContainer">                        
                
                <CLabel label={'Festival Lists'} />

                <div>
                    <Link to="/newFestival">                                
                        <CButton  onClick={() => {}}  > {'New Festival'} </CButton>
                    </Link>        

                    <Link to="/importFestival">
                        <CButton containerStyle={{marginLeft:10}} onClick={() => {}}  > {'Import Festival'} </CButton>
                    </Link>        
                </div>
            </div>

        
            <div style={{ height: '100%', width: '100%' }}>
                <DataGrid
                    rows={data}
                    rowCount={rowCount}
                    loading={isLoading}
                    rowsPerPageOptions={[5]}
                    pagination
                    page={page}
                    pageSize={10}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => {
                        //setPageSize(newPageSize)
                    }}
                    columns={columns}
                    initialState={{
                        pagination: {
                        page: 1,
                        },
                    }}
                />
            </div>

            <CModal
                isOpen={modalOpened}
                onRequestClose={()=>{onClose()}}
                contentLabel={"Strings.txtChangePassword"}
                contentStyle={{width: '470px', height: '108px'}}>

                <div className="cw-modal-change-password-container">
                    <CImageButton containerstyle={{    
                        position: 'absolute',
                        width: '48px',
                        height: '48px',
                        top: '24px',                        
                        right: '24px'}}
                        src={Images.close}
                        onClick={()=>{ onClose() }}/>

                    <div style={{marginTop:'10px'}}>
                        <label>Are you sure to delete it?</label>
                    </div>
                    
                    <div className="row">
                        <CButton containerStyle={{width:'160px', marginTop: '10px'}}
                                //isLoading={this.props.payorStore.isLoading} 
                                onClick={()=>{ onClose()}} >{"No"}</CButton>
                        <CButton containerStyle={{width:'160px', marginTop: '10px' , marginLeft:'15px'}}
                                //isLoading={this.props.payorStore.isLoading} 
                                onClick={()=>{ onDelete()}} >{"Yes"}</CButton>
                    </div>
                    
                </div>

            </CModal>

        
        </div>
    )

}
