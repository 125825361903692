

import React , {useState, useEffect} from 'react'
import CTextInput from 'components/atoms/CTextInput';
import CTextArea from 'components/atoms/CTextArea';
import CDatePicker from 'components/atoms/CDatePicker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function LeftInputForms(props) {

  const { festival , updateFestival }  = props;
  const [startDate, setStartDate] = useState(new Date());
  

  console.log("festival.startDate =" , festival )

  return (
    
      <div className="columnContainer">
        
        <CTextInput label={"Name"}
            placeholder={"Name"}
            value={festival? festival.name:''}
            onChangeValue={(value) => {  updateFestival("name", value); }}
            containerStyle={{ alignSelf: 'stretch', marginTop: '20px' }} />


        <CTextInput label={"Title"}
            placeholder={"Title"}
            value={festival? festival.title:''}
            onChangeValue={(value) => {  updateFestival("title", value); }}
            containerStyle={{ alignSelf: 'stretch' }} />
            
        <CTextArea containerStyle={{ alignSelf: 'stretch', }}
                label={"Description"}
                placeholder={"Description"}
                value={festival? festival.description:''}
                onChangeValue={(value) => { updateFestival("description", value) }} />

        <div className='row' style={{display:'flex', justifyContent:'flex-start' }}>
          <CDatePicker  containerStyle={{ alignSelf: 'stretch', marginTop: '10px' }} label={"Start Date"} 
              selected={festival && festival.startDate && festival.startDate != "" ? new Date(festival.startDate) : null}
              dateFormat="YYYY-MM-dd"
              onChange={(value , dt) =>  {  console.log(dt); setStartDate(value);  updateFestival("startDate" , dt + "T00:00:00" ) } }></CDatePicker>
          
          <CDatePicker  containerStyle={{ alignSelf: 'stretch', marginTop: '10px' , marginLeft:'10px' }} label={"End Date"} 
              selected={festival && festival.endDate && festival.endDate != "" ? new Date(festival.endDate ) : null}
              dateFormat="YYYY-MM-dd"
              onChange={(value , dt) =>  {  setStartDate(value);  updateFestival("endDate" , dt + "T00:00:00"  ) ; } }></CDatePicker>
        </div>
        
        
    </div>
    
  )
}
