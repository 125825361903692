

import React from 'react'
import { CTextInput } from 'components/atoms';

export default function InputForms(props) {

    const {artist, updateArtist} = props;
    
  return ( 
    
    <div className="columnContainer">
                    
        <CTextInput label={"Followers"}
            placeholder={"Followers"}
            value={artist? artist.followers:''}
            onChangeValue={(value) => {  updateArtist("followers", value); }}
            containerStyle={{ alignSelf: 'stretch' }} />

        <CTextInput label={"Genres"}
            placeholder={"Genres"}
            value={artist? artist.genres:''}
            onChangeValue={(value) => {  updateArtist("genres", value); }}
            containerStyle={{ alignSelf: 'stretch' }} />

        <CTextInput label={"Name"}
            placeholder={"Name"}
            value={artist? artist.name:''}
            onChangeValue={(value) => {  updateArtist("name", value); }}
            containerStyle={{ alignSelf: 'stretch' }} />

        <CTextInput label={"Popularity"}
            placeholder={"Popularity"}
            value={artist? artist.popularity:''}
            onChangeValue={(value) => {  updateArtist("popularity", value); }}
            containerStyle={{ alignSelf: 'stretch' }} />

    </div> 
    
  )
}
