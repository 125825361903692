
import "./app.css"

import {
  BrowserRouter as Router, Switch, Route, Routes, Link
} from "react-router-dom";
import Login from "./pages/auth/login/Login";
import MainFrame from "router/MainFrame";
import useToken from 'helper/useToken';


function App(props) {

  const { token, setToken } = useToken();

  console.log("toke", token);
  return (    
    token ? <MainFrame/> :
    <Router className="App"> 
      <Routes>
        <Route exact path="/" name="Login" element={<Login/>} />
        <Route exact path="/login" name="Login" element={<Login/>}  />        
      </Routes>
    </Router>    
  );
}

export default App;
