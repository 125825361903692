import React from 'react';
import "./topbar.css"
import {NotificationsNone , Language, Settings } from '@material-ui/icons';

const TopBar = () => {
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <span className="logo">Soundclub</span>
                </div>
                <div className="topRight">
                    {/* <div className="topbarIconsContainer">
                        <NotificationsNone/>
                        <san className="topIconBadge">2</san>
                    </div> */}
                    <div className="topbarIconsContainer">
                        <Language/>
                        <span className="topIconBadge">2</span>
                    </div>
                    {/* <div className="topbarIconsContainer">
                        <Settings/>
                        <san className="topIconBadge">2</san>
                    </div>
                    <img src="../../assets/logo.png" alt="" className="topAvatar" />                     */}
                </div>
            </div>
        </div>
    );
};

export default TopBar;