
import './categorylists.css'

import React , {  useEffect , useState  } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { getApiRequest } from '../../services/api';
import { useNavigate } from 'react-router-dom';

export default function CategoryLists({navigation}) {
    
    const [data, setData] = useState([])
    let navigate = useNavigate();   

    useEffect(() => {
        _callCategoryLists();
    }, []);

    const _callCategoryLists = () => {
        getApiRequest("categories", {}).then((res) =>{
            console.log("respnose",res.data);
            setData(res.data);
        }).catch((e) => {
            console.log("err" , e);
        })
    }
    
    const columns = [
        { field: 'id', headerName: 'ID', minWidth: 70  , maxWidth: 100},
        { field: 'title', headerName: 'Title', width: 300 , renderCell:(params) => {
            return (
                <div className="userListUser"> 
                    {/* <img src={params.row.avatar} alt="" /> */}
                    {params.row.title}
                </div>
            )
        } },
        { field: 'slug', headerName: 'Slug', width: 300 },
        
        {
            field: 'action',
            headerName: 'Action',
            width: 300,
            renderCell:(params) =>{
                return (
                    <>
                        <Link to={"/category/" + params.row.id}>
                            <button className="userListEdit">Edit</button>
                        </Link>
                        {/* <DeleteOutline className="userListDelete" onClick={() => {
                            console.log("delete ", params.row.id)
                            handleDelete(params.row.id)
                        }}/> */}
                    </>                    

                )
            }
        }
      ];      
    const handleDelete = (id) =>{
        setData(data.filter(item => item.id != id));        
    }

  return (
    <div className='categoryLists'>
      <h1 className="categoryListTitle">Category Lists</h1>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick        
      />

    </div>
  )
}
