
import './newartist.css';
import React , {useState, useEffect} from 'react'
import { CButton, CLabel, CTextInput } from 'components/atoms';
import { putApiRequest } from 'services/api';
import { useNavigate } from 'react-router-dom';
import InputForms from '../partial/InputForms';

export default function NewArtist() {

    const [artist, setArtist] = useState(null);    
    const navigate = useNavigate();

    const updateArtist = (key , event) =>{
        var tmp = {...artist};
        if(event.target){
            tmp[key] = event.target.value;
        }else{
            tmp[key] = event;
        }        
        setArtist(tmp); 
    }

    const _callAddArtist = () => {
        putApiRequest("artist", artist).then((res) => {
            navigate('/artists');
        }).catch((e) => {

        });
    }

  return (
    <div className='newArtistContainer'>
                     
            <div className="headerContainer">
                <CLabel label={'Add Festival'} />
            </div>
            
            <div className="rowContainer">   
                <InputForms artist={artist} updateArtist={updateArtist} ></InputForms>             
                <div className="columnContainer">
                </div>                  
            </div>

            <div className="updateBtnContainer">           
                <CButton  onClick={() => {_callAddArtist()}}  > { 'Add Festival'} </CButton>
            </div>           

        
    </div>
  )
}
