
import React from 'react'
import Sidebar from "./../components/sidebar/Sidebar";
import TopBar from "./../components/topbar/TopBar";
import Home from "./../pages/home/Home";
import {
  BrowserRouter as Router, Switch, Route, Routes, Link
} from "react-router-dom";
import UserList from "./../pages/userList/UserList";
import User from "./../pages/user/User";
import NewUser from "./../pages/user/NewUser";
import NewCategory from "./../pages/category/NewCategory";
import CategoryLists from "./../pages/category/CategoryLists";
import Category from "./../pages/category/Category";
import FestivalLists from "./../pages/festival/festival_lists/FestivalLists";
import EditFestival from "./../pages/festival/edit_festival/EditFestival";
import AddFestival from "./../pages/festival/add_festival/AddFestival";
import ImportFestival from "./../pages/festival/import_festival/ImportFestival";
import Login from "./../pages/auth/login/Login";
import ArtistLists from 'pages/artists/artist_lists/ArtistLists';
import ImportArtists from 'pages/artists/import_artists/ImportArtists';
import NewArtist from 'pages/artists/add_artist/NewArtist';
import EditArtist from 'pages/artists/edit_artist/EditArtist';


export default function MainFrame() {
  
    return (        
        <Router className="App">
          <TopBar></TopBar>
          <div className="container">
            <Sidebar/>
            <Routes>           
              <Route exact path="/login" element={<Home/>}/>
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/users" element={<UserList/>}/>
              <Route exact path="/user/:userId" element={<User/>}/>
              <Route exact path="/newUser" element={<NewUser/>}/>
    
              <Route exact path="/categories" element={<CategoryLists/>}/>
              <Route exact path="/newCategory" element={<NewCategory/>}/>
              <Route exact path="/category/:categoryId" element={<Category/>}/>
    
              <Route exact path="/festivals" element={<FestivalLists/>}/>
              <Route exact path="/festival/:festivalId" element={<EditFestival/>}/>
              <Route exact path="/newFestival" element={<AddFestival/>}/>
              <Route exact path="/importFestival" element={<ImportFestival/>}/>            

              <Route exact path="/artists" element={<ArtistLists/>}/>              
              <Route exact path="/newArtist" element={<NewArtist/>}/>
              <Route exact path="/artist/:artistId" element={<EditArtist/>}/>
              <Route exact path="/importArtist" element={<ImportArtists/>}/>
              

              <Route exact path="/logout" element={<Login/>}/>                    
            </Routes>        
          </div>
        </Router>
      );

}
