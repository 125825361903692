

import { Update } from "@material-ui/icons"
import React, { useEffect, useState } from 'react';
import "./category.css"
import { useLocation , useNavigate  } from 'react-router-dom';
import { getApiRequest, postApiRequest } from  '../../services/api';
import CButton from "components/atoms/CButton";

export default function Category(props) {
    
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const splitLocation = pathname.split("/");
  const [category, setCategory] = useState(null);


  useEffect(() => {
    if(splitLocation.length >=3 ){
      console.log(splitLocation[2]);
      _callCategory(splitLocation[2])
    }    
  }, []);


  const _callCategory = (categoryId) => {
    getApiRequest("category/" + categoryId , {}).then((res) => {
      console.log("re", res);
      setCategory(res.data);
      
    }).catch((e) => {

    });
  }
  const _callUpdateCategory = () => {
    console.log("update" ,category)
    postApiRequest("updateCategory" , {id:category.id, title:category.title, slug:category.slug} ).then((res) => {            
      navigate('/categories');
    }).catch((e) => {
      console.log("update" ,e)
    });
  }
  
  return (
    <div className="editCategory">

        <h1> Edit Category</h1>      

        <div className="itemContainer">
            <label>Title</label>
            <input type='text' placeholder="joone@gmail.com" name="email" id='email' value={category? category.title:''} 
            onChange={(event) => {                 
                var tmp = {...category};
                tmp.title = event.target.value;
                setCategory(tmp); 
              }}
            />
        </div>  

        <div className="itemContainer">
            <label>Slug</label>
            <input type='text' placeholder="joone@gmail.com" name="email" id='email' value={category? category.slug:''}
              onChange={(event) => {                 
                var tmp = {...category};
                tmp.slug = event.target.value;
                setCategory(tmp); 
              }}
             />            
        </div>  

        <div className="updateBtnContainer">
          <CButton  onClick={() => {_callUpdateCategory()}}  > { 'Update'} </CButton>          
        </div>

    </div>
  )
}
