
import React , {useState, useEffect} from 'react'
import { CButton, CLabel, } from 'components/atoms';
import { getApiRequest, postApiRequest } from 'services/api';
import InputForms from '../partial/InputForms';
import { useLocation , useNavigate  } from 'react-router-dom';

export default function EditArtist() {

    const [artist, setArtist] = useState(null);    
    const [postData, setPostData] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");


    useEffect(() => {
        if(splitLocation.length > 0 ){          
            console.log("artist id", splitLocation[splitLocation.length - 1])
            _callArtist(splitLocation[splitLocation.length - 1]);
          setPostData({id:splitLocation[splitLocation.length - 1]});
        } 
    },[]);

    const updateArtist = (key , event) =>{
        var tmp = {...artist};
        if(event.target){
            tmp[key] = event.target.value;
        }else{
            tmp[key] = event;
        }        
        setArtist(tmp); 
        var postTmp = {...postData};
        if(event.target){
            postTmp[key] = event.target.value;
        }else{
            postTmp[key] = event;
        }    
        setPostData(postTmp);
    }

    const _callArtist = (id) => {

        getApiRequest("artist/" + id, {}).then((res) => {
            console.log("artist", res);
            setArtist(res.data)
        }).catch((e) => {

        });
    }
    const _callUpdateArtist = () => {
        
        postApiRequest("artist", postData).then((res) => {
            navigate('/artists');
        }).catch((e) => {

        });
    }

  return (
    <div className='newArtistContainer'>
                     
            <div className="headerContainer">
                <CLabel label={'Edit Artist'} />
            </div>
            

            <div className="rowContainer">   
                <InputForms artist={artist} updateArtist={updateArtist} ></InputForms>             

                <div className="columnContainer">
                </div>                  
            </div>

            <div className="updateBtnContainer">           
                <CButton  onClick={() => {_callUpdateArtist()}}  > { 'Update Artist'} </CButton>
            </div>           

        
    </div>
  )
}
