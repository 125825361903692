
import "./widgetsm.css"

export default function WidgetSm(props) {
  return (
    <div className="widgetSm">
      {props.title}
    </div>
  )
}
