import "./featuredInfo.css"
import { ArrowDownward , ArrowUpward } from "@material-ui/icons"
import React, {useState, useEffect} from 'react'
import { getApiRequest } from "services/api";

export default function FeaturedInfo() {


    const [data, setData] = useState([]);

    useEffect(() =>{
        _callHomePageData()
    },[]);

    const _callHomePageData = () =>{
        getApiRequest("home", {}).then((res) => {
            console.log("Final" , res.data)
            setData(res.data);
        }).catch((e) => {

        });
    }
  return (
    <div className="featured">
        {
            data.map((item, index) => {
                return (<div className="featuredItem">
                    <span className="featuredTitle">{item.title}</span>
                    <div className="featuredMoneyContainer">
                        <span className="featuredMoney">{item.count}</span>
                        {/* <span className="featuredMoneyRate">
                            -22.33  <ArrowDownward className="featuredIcon negative"/>
                        </span> */}
                    </div>
                    <span className="featuredSub">Compared to last month</span>
                </div>)
            })
        }        
    </div>
  )
}
