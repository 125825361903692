

import "./newcategory.css"

export default function NewCategory() {
  return (
    <div className="newCategory">
        new category
    </div>
  )
}
