

import React , {  useEffect , useState  } from 'react';

import { postMultipartRequest } from "../../../services/api";

export default function ImportArtists() {

    const [image_preview, setImagePreview] = useState(null);
    const [image_file, setImageFile] = useState(null);
    const [uploadFile, setUploadFile] = useState(null);

    useEffect(() => {

    });
    

    const handleImagePreview = (e) => {
        //let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        //setImagePreview(image_as_base64);
        ///setImageFile(image_as_files);        
        setUploadFile(image_as_files);        
        
    }

    const handleSubmitFile = () => {

        if (uploadFile !== null){
            let formData = new FormData();
            formData.append('customFile', uploadFile);            
            console.log(formData);            
            postMultipartRequest( "importArtist",  formData).then((res) => {
                alert("success");
            }).catch((e) => {
                alert("failed");
            });            
        }
    }


  return (
    <div className="importFestivalContainer">      
        <h1 className="text">Import Artist Data from CSV</h1>

            {
                image_preview != null && 
                <img src={image_preview} alt="image preview" style={{width: 300}}/>
            }
            
            <input
                style={{marginTop:'20px'}}
                type="file"
                onChange={handleImagePreview}
            />

            <label>Upload file</label>
            <input type="submit" onClick={handleSubmitFile} value="Submit"/>
                        

    </div>
  )
}
