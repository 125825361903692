import { MailOutline, PermIdentity, PhoneOutlined, PublishOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import "./user.css";

export default function User() {
  return (
    <div className='user'>
            
      <div className="userTitleContainer">
        <h1 className="userTitle">Edit User</h1>
        <Link to="/newUser">
          <button className="userAddButton">Create</button>
        </Link>
        
      </div>

      <div className="userContainer">
        <div className="userShow">
          
          <div className="userShowTop">
            <img src="https://www.learningcontainer.com/wp-content/uploads/2020/08/Sample-Small-Image-PNG-file-Download.png" 
            alt="" className="userShowImage" />
            <div className="userShowTopTitle">
              <span className="userShowUserName">Anna dfd</span>
              <span className="userShowUserTitle">Article  user</span>
            </div>
          </div>

          <div className="userShowBottom">

            <span className="userShowTitle">Account Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon"/>
              <span className="userShowInfoTitle">annback 99 </span>
            </div>            

            <div className="userShowInfo">
              <MailOutline className="userShowIcon"/>
              <span className="userShowInfoTitle">tst@gmail.com </span>
            </div>            

            <div className="userShowInfo">
              <PhoneOutlined className="userShowIcon"/>
              <span className="userShowInfoTitle"> phone </span>
            </div>            

          </div>          
        </div>

        <div className="userUpdate">          
            <span className="userUpdateTitle">Edit</span>            
            <form action="" className="userUpdateForm">
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Username</label>
                  <input type="text" placeholder="phone" className="userUpdateInput" />
                </div>

                <div className="userUpdateItem">
                  <label>Email</label>
                  <input type="text" placeholder="email" className="userUpdateInput" />
                </div>

                <div className="userUpdateItem">
                  <label>Address</label>
                  <input type="text" placeholder="address" className="userUpdateInput" />
                </div>

              </div>
              <div className="userUpdateRight">
                <div className="userUpdateUpload">
                  <img src="https://www.learningcontainer.com/wp-content/uploads/2020/08/Sample-Small-Image-PNG-file-Download.png" alt="" 
                  className="userUpdateImage" />
                  <label htmlFor="file"> <PublishOutlined className="userUpdateIcon"/> </label>
                  <input type="file" id="file" style={{display:"none"}} ></input>
                </div>
                <button className="userUpdateButton">Update</button>
              </div>
            </form>
        </div>
    
      </div>

    </div>
  )
}
