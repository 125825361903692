import "./userlist.css"
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline  } from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import React , {  useEffect , useState  } from 'react';
import { getApiRequest } from "services/api";
import useToken from 'helper/useToken';
import { CLabel } from "components/atoms";

export default function UserList() {
    
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const { token, setToken } = useToken();
  const navigate  = useNavigate();

    const columns = [
        { field: 'id', headerName: 'ID', minWidth: 70  , maxWidth: 100},
        { field: 'username', headerName: 'User Name', width: 300 , renderCell:(params) => {
            return (
                <div className="userListUser"> 
                    {/* <img src={params.row.avatar} alt="" /> */}
                    {params.row.username}
                </div>
            )
        } },
        { field: 'email', headerName: 'Email Address', width: 300 },
        
        {
            field: 'action',
            headerName: 'Action',
            width: 300,
            renderCell:(params) =>{
                return (
                    <>
                        <Link to={"/user/" + params.row.id}>
                            <button className="userListEdit">Edit</button>
                        </Link>
                        <DeleteOutline className="userListDelete" onClick={() => {
                            console.log("delete ", params.row.id)
                            handleDelete(params.row.id)
                        }}/>
                    </>                    
                )
            }
        }
    ];
        
    useEffect(() => {
        _callArtistLists();      
    }, [page]);

    const _callArtistLists = () => {
        
        setIsLoading(true);        
        getApiRequest("users", { pageSize: 10, pageIndex: page + 1, isTag:0} , token).then((res) =>{            

            setIsLoading(false);
            if(res != null){
              setRowCount(res.totalCount);
              setData(res.data);
            }else{
              setToken({token: null});
              navigate('/');
              window.location.reload(); 
            }
                       
        }).catch((e) => {
            console.log("err" , e);
            setIsLoading(false);
        })
    }
    
    const handleDelete = (id) =>{
        setData(data.filter(item => item.id != id));        
    }

    return (
      <div className='userList'>
        
        <div className="headerContainer">
            <CLabel label={'User Lists'} />            
        </div>
      

        <DataGrid
              rows={data}
              rowCount={rowCount}
              loading={isLoading}
              rowsPerPageOptions={[5]}
              pagination
              page={page}
              pageSize={10}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                  //setPageSize(newPageSize)
              }}
              columns={columns}
              initialState={{
                  pagination: {
                    page: 1,
                  },
              }}
        />

      </div>
    )
}
