

import "./importfestival.css";
import React , {  useEffect , useState  } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { postMultipartRequest } from "../../../services/api";
import CButton from "components/atoms/CButton";

export default function ImportFestival() {

    const [image_preview, setImagePreview] = useState(null);
    const [image_file, setImageFile] = useState(null);
    const [uploadFile, setUploadFile] = useState(null);

    useEffect(() => {

    });
    

    const handleImagePreview = (e) => {
        //let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        //setImagePreview(image_as_base64);
        ///setImageFile(image_as_files);        
        setUploadFile(image_as_files);
        console.log("selected file", image_as_files);
        
    }

    const handleSubmitFile = () => {

        if (uploadFile !== null){

            let formData = new FormData();
            formData.append('customFile', uploadFile);
            // the image field name should be similar to your api endpoint field name
            // in my case here the field name is customFile
            console.log(formData);
            
            postMultipartRequest( "importFestival",  formData).then((res) => {
                alert("success");
            }).catch((e) => {
                alert("failed");
            });            
        }
    }


  return (
    <div className="importFestivalContainer">      
        <h1 className="text">Import Festival Data from CSV</h1>

            {
                image_preview != null && 
                <img src={image_preview} alt="image preview" style={{width: 300}}/>
            }
            
            <input
                style={{marginTop:'20px'}}
                type="file"
                onChange={handleImagePreview}
            />
            <label>Upload file</label>
            <input type="submit" onClick={handleSubmitFile} value="Submit"/>
            
            {/* <CButton  onClick={() => {handleSubmitFile}}  > {'Submit'} </CButton> */}

    </div>
  )
}
