import axios from "axios";

const BASE_URL = "http://ec2-18-171-16-205.eu-west-2.compute.amazonaws.com/backend/v1/api/";
//const BASE_URL = "http://localhost:5000/v1/api/";

export const getApiRequest = async ( url ,  param , token ) => {
            

    var request_url = BASE_URL + url;
    console.log(request_url);
    console.log(token);

    return new Promise(function (resolve, reject) {
      axios
        .get(request_url, {
          params: param,
          headers: {
            Authorization: 'Bearer ' + token            
          }
        })
        .then((res) => {         
          console.log("res",res.data)          
          if (res.data.success === true) {
            resolve(res.data);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
            reject(err);
        })
    });
}

export const postApiRequest = async (url, postData) => {
  var token = '';
  var request_url = BASE_URL + url;

  return new Promise(function (resolve, reject) {    
    axios
      .post(request_url, postData, {
        headers: {
          Authorization: 'Bearer ' + token          
        }
      })
      .then((res) => {      
        console.log("res", res.data);
      
        if (res.data.success === true) {
          resolve(res.data);
        }
        resolve(0);
      })
      .catch((err) => {
        if(err.status == 400){
          reject(err)
        }
        console.log("ad", JSON.stringify(err));
        reject(err);
      })
  });
}

export const putMultipartApiRequest = async (url, formData) =>{  
  var token = '';
  var request_url = BASE_URL + url;
  return new Promise(function (resolve, reject) {    
    axios.put(
      request_url,
      formData,
      {
          headers: {
              "Authorization": "",
              "Accept":"application/json",
              "Content-type": "multipart/form-data",
          },
      }
    )
    .then(res => {
      console.log(res.data);
        if (res.data.success === true) {
          resolve(res.data);
        }else{
          resolve(res.data);
        }          
    })
    .catch(err => {
        console.log(err);
        reject(err);
    })
       
  });
}


export const putApiRequest = async (url, postData) => {

  var token = '';
  var request_url = BASE_URL + url;

  return new Promise(function (resolve, reject) {    
    axios
      .put(request_url, postData, {
        headers: {
          Authorization: 'Bearer ' + token          
        }
      })
      .then((res) => {      
        console.log("res", res.data);
      
        if (res.data.success === true) {
          resolve(res.data);
        }
        resolve(0);
      })
      .catch((err) => {
        if(err.status == 400){
          reject(err)
        }
        console.log("ad", JSON.stringify(err));
        reject(err);
      })
  });
}


export const postMultipartRequest = async(url, formData) => {

  var token = '';
  var request_url = BASE_URL + url;

  return new Promise(function (resolve, reject) {    
      axios.post(
        request_url,
        formData,
        {
            headers: {
                "Authorization": "",
                "Content-type": "multipart/form-data",
            },                    
        }
      )
      .then(res => {
        console.log(res.data);
          if (res.data.success === true) {
            resolve(res.data);
          }else{
            resolve(res.data);
          }          
      })
      .catch(err => {
          console.log(err);
          reject(err);
      })
      
  });

  

}



export const deleteApiRequest = async ( url ,  param , token ) => {
            
  var request_url = BASE_URL + url;  
  return new Promise(function (resolve, reject) {
    axios
      .delete(request_url, {
        params: param,
        headers: {
          Authorization: 'Bearer ' + token            
        }
      })
      .then((res) => {         
        console.log("res",res.data)          
        if (res.data.success === true) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
          reject(err);
      })
  });
}
