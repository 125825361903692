import "./artistlists.css";
import React , {  useEffect , useState  } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { deleteApiRequest, getApiRequest } from '../../../services/api';
import { DeleteOutline, ImageSearch } from "@material-ui/icons";
import {  CImageButton, CModal, CButton ,CLabel } from "components/atoms";
import Images from "constants/Images";

export default function ArtistLists() {
    
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [id, setId] = useState(0);
    const [modalOpened, setModalOpened] = useState(false);

    const columns = [
        { field: 'id', headerName: 'ID', minWidth: 70  , maxWidth: 100},
        { field: 'name', headerName: 'Name', width: 450 , renderCell:(params) => {
            return (
                <div className="userListUser">                     
                    {params.row.name}
                </div>
            )
        } },
        { field: 'followers', headerName: 'Followers', width: 100 },
        { field: 'genres', headerName: 'Genres', width: 200 },
        { field: 'popularity', headerName: 'Popularity', width: 100 },
        
        {
            field: 'action',
            headerName: 'Action',
            width: 300,
            renderCell:(params) =>{
                return (
                    <>
                        <Link to={"/artist/" + params.row.id}>
                            <button className="userListEdit">Edit</button>
                        </Link>
                        
                        <DeleteOutline className="userListDelete" onClick={() => {
                            console.log("delete ", params.row.id)
                            handleDelete(params.row.id)
                        }}/>
                    </>                    

                )
            }
        }
    ];   

    useEffect(() => {
        _callArtistLists();      
    }, [page]);

    const _callArtistLists = () => {
        setIsLoading(true);
        getApiRequest("artists", { pageSize: 10, pageIndex: page + 1, isTag:0}).then((res) =>{            
            setRowCount(res.totalCount);
            setData(res.data);
            setIsLoading(false);
        }).catch((e) => {
            console.log("err" , e);
            setIsLoading(false);
        })
    }
        
    const handleDelete = (id) =>{
        setId(id);
        setModalOpened(true);
        //setData(data.filter(item => item.id != id));        
    }

    const onClose = () => {
        setModalOpened(false);
    }

    const onDelete = () => {
        deleteApiRequest("artist/" + id , {}).then((res) => {            
            setData(data.filter(item => item.id != id));        
            setModalOpened(false);
        }).catch((e) => {
            console.log(e);
        })
    }

    return (
        <div className="festivalLists">
        
            <div className="headerContainer">
                <CLabel label={'Artist Lists'} />
                <div>              
                    <Link to="/newArtist"> 
                        <CButton containerStyle={{marginLeft:10}} onClick={() => {}}  > {'New Artist'} </CButton>
                    </Link>        
                    <Link to="/importArtist">                    
                        <CButton containerStyle={{marginLeft:10}} onClick={() => {}}  > {'Import Artists'} </CButton>
                    </Link>        
                </div>        
            </div>
      

            <DataGrid
                rows={data}
                rowCount={rowCount}
                loading={isLoading}
                rowsPerPageOptions={[5]}
                pagination
                page={page}
                pageSize={10}
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => {
                    //setPageSize(newPageSize)
                }}
                columns={columns}
                initialState={{
                    pagination: {
                    page: 1,
                    },
                }}
            />

            <CModal
                isOpen={modalOpened}
                onRequestClose={()=>{onClose()}}
                contentLabel={"Strings.txtChangePassword"}
                contentStyle={{width: '470px', height: '108px'}}>

                <div className="cw-modal-change-password-container">
                    <CImageButton containerstyle={{    
                        position: 'absolute',
                        width: '48px',
                        height: '48px',
                        top: '24px',                        
                        right: '24px'}}
                        src={Images.close}
                        onClick={()=>{ onClose() }}/>

                    <div style={{marginTop:'10px'}}>
                        <label>Are you sure to delete it?</label>
                    </div>
                    
                    <div className="row">
                        <CButton containerStyle={{width:'160px', marginTop: '10px'}}
                                //isLoading={this.props.payorStore.isLoading} 
                                onClick={()=>{ onClose()}} >{"No"}</CButton>
                        <CButton containerStyle={{width:'160px', marginTop: '10px' , marginLeft:'15px'}}
                                //isLoading={this.props.payorStore.isLoading} 
                                onClick={()=>{ onDelete()}} >{"Yes"}</CButton>
                    </div>                    
                </div>
            </CModal>



        </div>
    )

}
