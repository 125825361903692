

import React  , {useState, useEffect} from 'react'
import CTextInput from 'components/atoms/CTextInput';
import CMImagePicker from 'components/molecules/CMImagePicker';
import  {AppStrings} from 'constants/Strings';

export default function RightInputForms(props) {

    const { festival , updateFestival } = props;
    const [imgUrl, setImageUrl] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const handleMultipleImages = (event) =>{ 
        const files = event.target.files;
        updateFestival("photos", files[0]);
        // for (let i = 0; i < files.length; i++) {            
            
        // }
    }
  
  return (
    
    <div className="columnContainer">
                
        <CMImagePicker
            containerStyle={{}}
            label={AppStrings.thumbImage}
            buttonLabel={"Thumb Image"}
            description={"Select Image"}
            isUploading={false}
            imageUrl={imgUrl}
            onClearImage={() => { setImageUrl(null) }}
            onSelectImage={(imgURL, file) => {                                
                setImageUrl(imgURL);
                updateFestival("customFile", file);
            }}
            isRemoteUrlOnly
            // error={this.state.errImageUrl}
            disable={isEdit} />


        <div style={{marginTop:'10px'}}>
            <label>Photos</label>
        </div>
        <input type="file" multiple onChange={handleMultipleImages} />

        <CTextInput label={"Country"}
            placeholder={"Country"}
            value={festival? festival.country:''}
            onChangeValue={(value) => {  updateFestival("country", value); }}
            containerStyle={{ alignSelf: 'stretch' , marginTop:'10px' }} />


        <CTextInput label={"City"}
            placeholder={"City"}
            value={festival? festival.city:''}
            onChangeValue={(value) => {  updateFestival("city", value); }}
            containerStyle={{ alignSelf: 'stretch' }} />
                            
    </div>  
    
  )
}
