
import React from 'react';
import "./sidebar.css"
import { LineStyle , 
    Timeline, 
    TrendingUp , 
    PersonOutline , 
    Category , 
    AccountBoxOutlined } from "@material-ui/icons";

    import { Link , useLocation, useNavigate } from 'react-router-dom';
import useToken from '../../helper/useToken';

const Sidebar = () => {
    
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const navigate = useNavigate();
    const { token, setToken } = useToken();

    const logout = () => {
        sessionStorage.clear();
        setToken({token: null});
        navigate('/');
        window.location.reload(); 
    }

    return (
        <div className='sidebar'>
            <div className="sidebarWrapper">
                <div className="sidebarMenu">

                    <h3 className="sidebarTitle">Dashboard</h3>

                    <ul className="sidebarList">
                        <Link to="/" className='link'>
                            <li  className={splitLocation[1] === "" ? "sidebarListItem active" : "sidebarListItem"}>
                                <LineStyle className="sidebarIcon"/>
                                Home
                            </li>
                        </Link>
                                              
                        <Link to="/users" className='link'>
                            <li className={splitLocation[1] === "users" ? "sidebarListItem active" : "sidebarListItem"}>
                                <PersonOutline className="sidebarIcon"/>
                                User
                            </li>
                        </Link>
                        
                        {/* <Link to="/newUser" className='link'>
                            <li className={splitLocation[1] === "newUser" ? "sidebarListItem active" : "sidebarListItem"}>
                                <AccountBoxOutlined className="sidebarIcon"/>
                                Profile
                            </li>
                        </Link> */}
                                                
                    </ul>
                </div>

                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Material</h3>
                    <ul className="sidebarList">
                        <li className="sidebarListItem">
                            <LineStyle className="sidebarIcon"/>
                            Venues
                        </li>
                        <li className="sidebarListItem">
                            <LineStyle/>
                            Events
                        </li>
                        <Link to="/festivals" className='link' >
                            <li  className={splitLocation[1] === "festivals" || splitLocation[1] === "newFestival" ? "sidebarListItem active" : "sidebarListItem"}>
                                <Timeline className="sidebarIcon"/>
                                Festivals
                            </li>
                        </Link>
                        
                        <Link to="/artists" className='link'>
                            <li className={splitLocation[1] === "artists"  
                            || splitLocation[1] === "newArtist"
                            || splitLocation[1] === "importArtist" ? "sidebarListItem active" : "sidebarListItem"}>
                                <TrendingUp className="sidebarIcon"/>
                                Artists
                            </li>
                        </Link>
                        

                    </ul>
                </div>

                {/* <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Basic Info</h3>
                    <ul className="sidebarList">
                        <Link to="/categories" className='link'>
                            <li className={splitLocation[1] === "categories" ? "sidebarListItem active" : "sidebarListItem"}>
                                <Category className="sidebarIcon"/>
                                Category
                            </li>    
                        </Link>
                        
                        <li className="sidebarListItem">
                            <Timeline className="sidebarIcon"/>
                            Posts
                        </li>
                        <li className="sidebarListItem">
                            <TrendingUp className="sidebarIcon"/>
                            Walls
                        </li>
                    </ul>
                </div> */}

                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Settings</h3>
                    <ul className="sidebarList">
                        {/* <li className="sidebarListItem">
                            <LineStyle className="sidebarIcon"/>
                            Countries
                        </li> */}
                        
                        <li className="sidebarListItem" onClick={logout}>
                            <Category className="sidebarIcon"/>
                            Logout
                        </li>                                                                    
                    </ul>
                </div>




            </div>
        </div>
    );
};

export default Sidebar;