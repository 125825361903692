import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo"
import WidgetSm from "../../components/widgetSm/WidgetSm"
import "./home.css"
import { CLabel } from "components/atoms";

export default function Home() {
  return (
    <div className="home">

      <div className="headerContainer">
          <CLabel label={'Welcome to Soundclub'} />            
      </div>

      <FeaturedInfo/>
      <div className="homeWidgets">
          <WidgetSm title="Category"/>
          <WidgetSm title="Post"/>
          <WidgetSm title="Walls"/>
          
      </div>
    </div>
  )
}
