

import "./addfestival.css";
import React, { useEffect, useState} from 'react';
import { putApiRequest, putMultipartApiRequest } from "../../../services/api";
import { useLocation , useNavigate  } from 'react-router-dom';
import CButton from "components/atoms/CButton";
import CLabel from "components/atoms/CLabel";
import LeftInputForms from "../partial/LeftInputForms";
import RightInputForms from "../partial/RightInputForms";


export default function AddFestival() {

    const [festival, setFestival] = useState(null);    
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    useEffect(() => {        
    }, []);
          
    const updateFestival = (key , event) =>{
        var tmp = {...festival};
        if(event.target){
            tmp[key] = event.target.value;
        }else{
            tmp[key] = event;
        }        
        setFestival(tmp); 
    }

    const addPostVal = (key, value) => {
        var tmp = {...festival};
        tmp[key] = value;
        setFestival(tmp);
    }
      
    const _callUpdateFestival = () => {
                
        addPostVal("profileId", 1);
        addPostVal("categoryId", 3);
        const formData = new FormData();
        for(var key of Object.keys(festival)){
            formData.append(key, festival[key]);
        }
        
        putMultipartApiRequest("festival" , formData ).then((res) => {            
          navigate('/festivals');
        }).catch((e) => {
          console.log("update" ,e)
        });
    }

    return (
        <div className='addFestival'>
            
            <CLabel label={'Add Festival'} />

            <div className="rowContainer2">
                
                <LeftInputForms festival={festival} updateFestival={(key, value) => {updateFestival(key, value); }}></LeftInputForms>

                <RightInputForms  festival={festival} updateFestival={(key, value) => {updateFestival(key, value); }}></RightInputForms>

            </div>

            <div className="updateBtnContainer">           
                <CButton  onClick={() => {_callUpdateFestival()}}  > { 'Add Festival'} </CButton>
            </div>           

        </div>
    )
}
